import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Spin,
  Table,
} from 'antd';
import axios from 'axios';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import ReactToPrint from 'react-to-print';
import { bindActionCreators } from 'redux';
import client from '../../feathers';
import Layout from '../layout';
import requireAuth from '../requireAuth';

const _ = require('lodash');

const { TextArea } = Input;

const { MonthPicker, RangePicker, WeekPicker } = DatePicker;

class ComponentToPrint extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      //todaydate : new Date(),
      dataSource: [],
      accountingPeriods: {},
      jmb: {},
      totalCredit: 0,
      totalDebit: 0,
      ledgerAccount: {},
      balanceAmount: 0,
      propertyunit: {},
      owner: {},
      fromDate: '',
      toDate: '',
      setting: [],
      invoiceitems: [],
      totalbalanceAmount: 0,
      loadingData: false,
    };

    this._getData = this._getData.bind(this);
  }

  componentDidUpdate() {
    if (this.props.fromDate !== this.state.fromDate) {
      this.setState({ fromDate: this.props.fromDate }, () => {
        this._getData();
      });
    }

    if (this.props.toDate !== this.state.toDate) {
      this.setState({ toDate: this.props.toDate }, () => {
        this._getData();
      });
    }
  }

  _getData() {
    let queryString = '';

    if (this.state.ledgerAccount.accountNo === '3000') {
      queryString = { IsDebtor: false };
    } else {
      queryString = { IsDebtor: true };
    }

    if (this.state.fromDate && this.state.toDate) {
      this.setState({
        loadingData: true,
      });
      client
        .authenticate()
        .then((res) => {
          // get sum
          return axios.get(`${client.io.io.uri}ledgerTrxBalanceSOA`, {
            params: {
              startDate: moment(this.state.fromDate).toDate(),
              endDate: moment(this.state.toDate).toDate(),
              ledgerAccountId: this.props.id,
              ...queryString,
            },
            headers: {
              Authorization: client.settings.storage.storage['feathers-jwt'],
            },
          });
        })
        .then((res) => {
          // process cumulative value
          let data = res.data;
          _.map(data, function (val, i) {
            if (i > 0) {
              val.balance = parseFloat(
                data[i - 1].balance +
                (val.creditAmount ? val.creditAmount * -1 : (val.debitAmount || 0))
              );
            } else {
              val.balance = parseFloat(
                val.creditAmount ? val.creditAmount * -1 : (val.debitAmount || 0)
              );
            }
          });

          this.setState({
            datasource: data,
            loadingData: false,
            totalbalanceAmount: data[data.length - 1].balance.toFixed(2),
            loading: false,
          });
        })
        .catch((err) => {
          this.setState({
            loadingData: false,
          });
          console.log({ err });
        });
    }
  }

  componentWillMount() {
    client
      .authenticate()
      .then((res) => {
        return Promise.all([
          client.service('propertyunits').find({
            query: {
              ledgerAccountId: this.props.id,
              $populate: 'ledgerAccountId',
              $limit: 1,
            },
          }),
          client.service('accounting-periods').find({
            query: {
              companyId: this.props.companyId,
              $sort: {
                createdAt: -1,
              },
              $limit: 1,
            },
          }),
          client.service('jmbs').find({
            query: {
              $populate: 'propertyId',
              propertyId: this.props.companyId,
              status: 'ACTIVE',
              $sort: {
                createdAt: -1,
              },
              $limit: 1,
            },
          }),
          client.service('settings').find({
            query: {
              propertyId: this.props.companyId,
            },
          }),
          client.service('ledger-accounts').find({
            query: {
              companyId: this.props.companyId,
              _id: this.props.id,
            },
          }),
        ]);
      })
      .then(([pUnit, accPeriod, jmb, settings, ledgerAccount]) => {
        if (pUnit.total > 0) {
          this.setState({
            propertyunit: pUnit.data[0],
            owner: pUnit.data[0].owner,
          });
        }
        if (accPeriod.total > 0) {
          this.setState({ accountingPeriods: accPeriod.data[0] });
        }
        if (jmb.total > 0) {
          this.setState({ jmb: jmb.data[0] });
        }
        if (settings.total > 0) {
          this.setState({ setting: settings.data[0].tnc });
        }
        if (ledgerAccount.total > 0) {
          this.setState({ ledgerAccount: ledgerAccount.data[0] });
        }
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
      });
  }

  //---------------------------------------------------------------------------------------
  convertDate(date = null) {
    let tempDate = new Date(date);
    let d = tempDate.getDate();
    let m = tempDate.getMonth() + 1;
    let y = tempDate.getFullYear();
    return date ? d + '/' + m + '/' + y : '';
  }

  //---------------------------------------------------------------------------------------
  fromDateOnChange(e) {
    this.setState({
      fromDate: e.target.value,
    });
  }

  toDateOnChange(e) {
    this.setState({
      toDate: e.target.value,
    });
  }

  //---------------------------------------------------------------------------------------
  renderDescriptions(record) {
    var tempValue = _.find(this.state.invoiceitems, {
      docId: record.description,
    });
    if (tempValue === undefined) {
      return record.remark ? record.remark : record.description;
    }
    return tempValue ? tempValue.description : '';
  }

  //---------------------------------------------------------------------------------------
  specialCaterRecordData(record) {
    if (record.trancode) {
      if (record.receiptId) {
        return record.receiptId.receiptNo ? record.receiptId.receiptNo : '';
      } else {
        return record.referenceId ? record.referenceId : record.description;
      }
    }
    return record.referenceId ? record.referenceId : record.description;
  }
  //---------------------------------------------------------------------------------------

  render() {
    const dataSource = this.state.dataSource;
    const loading = this.state.loading;
    let { totalbalanceAmount } = this.state;
    const propertyunit = this.state.propertyunit;
    const owner = this.state.owner;

    const columns = [
      {
        // title: 'Transaction Date',
        title: 'Date',
        dataIndex: 'trxDate',
        width: '12%',
        render: (text, record) => (
          <span style={{ fontSize: '.8em' }}>{this.convertDate(text)}</span>
        ),
      },
      {
        title: 'Doc No.',
        dataIndex: 'description',
        width: '23%',
        render: (text, record) => (
          <span style={{ fontSize: '.8em' }}>
            {this.specialCaterRecordData(record)
              .split('<br />')
              .map((i) => (
                <span style={{}}>
                  {i} <br />
                </span>
              ))}
          </span>
        ),
      },
      {
        title: 'Description',
        dataIndex: 'description2',
        width: '29%',
        render: (text, record) => {
          return (
            <span style={{ fontSize: '.8em' }}>
              {this.renderDescriptions(record)
                .split('<br />')
                .map((i) => (
                  <span style={{}}>
                    {i} <br />
                  </span>
                ))}
            </span>
          );
        },
      },
      {
        title: <span style={{ float: 'right' }}>Debit</span>,
        align: 'right',
        render: (text, record) => (
          <span style={{ fontSize: '.8em', float: 'right' }}>
            {(record.debitAmount || 0).toFixed(2)}
          </span>
        ),
      },
      {
        title: <span style={{ float: 'right' }}>Credit</span>,
        align: 'right',
        render: (text, record) => (
          <span style={{ fontSize: '.8em', float: 'right' }}>
            {(record.creditAmount || 0).toFixed(2)}
          </span>
        ),
      },
      {
        title: <span style={{ float: 'right' }}>Balance</span>,
        align: 'right',
        render: (text, record) => {
          if (record.balance < 0) {
            return (
              <span style={{ fontSize: '.8em', float: 'right' }}>
                ({Math.abs(record.balance || 0).toFixed(2)})
              </span>
            );
          } else {
            return (
              <span style={{ fontSize: '.8em', float: 'right' }}>
                {(record.balance || 0).toFixed(2)}
              </span>
            );
          }

          // return (<span style={{ fontSize: '.8em', float: 'right' }}>
          //   {(record.balance || 0).toFixed(2)}
          // </span>)
        },
      },
    ];

    return (
      <Spin spinning={this.state.loading}>
        <Card
          title={'INVOICE cum STATEMENT of ACCOUNT'}
          // title={ledgerAccount.fullAccountNo+' - '+ledgerAccount.fullAccountNo}

          // extra={this.convertDate(this.state.accountingPeriods.fromDate)+' - '+this.convertDate(this.state.accountingPeriods.toDate)}

          loading={loading}
        >
          <Row>
            <Col span={5}>
              <img
                width='100%'
                src={this.state.jmb ? this.state.jmb.headerFile : ''}
              />
            </Col>
            <Col span={19}>
              <p style={{ margin: '5px' }}>
                <span>{this.state.jmb ? this.state.jmb.headerLine1 : ''}</span>{' '}
                {this.state.jmb.registrationNo
                  ? `(${this.state.jmb.registrationNo})`
                  : ''}
              </p>
              <p style={{ margin: '5px' }}>
                <span>{this.state.jmb ? this.state.jmb.headerLine2 : ''}</span>
              </p>
              <p style={{ margin: '5px' }}>
                <span>{this.state.jmb ? this.state.jmb.headerLine3 : ''}</span>
              </p>
              <p style={{ margin: '5px' }}>
                <span>{this.state.jmb ? this.state.jmb.headerLine4 : ''}</span>
              </p>
            </Col>
          </Row>

          <br />

          <Row>
            <Row gutter={24}>
              <Col span={4}>
                <span>Generated on</span>
              </Col>
              <Col span={1}>
                <span>:</span>
              </Col>
              <Col span={18}>
                <span>{moment().format('YYYY-MM-DD')}</span>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={4}>
                <span>Unit</span>
              </Col>
              <Col span={1}>
                <span>:</span>
              </Col>
              <Col span={18}>
                <span>{propertyunit.referenceId}</span>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={4}>
                <span>Name</span>
              </Col>
              <Col span={1}>
                <span>:</span>
              </Col>
              <Col span={18}>
                <span>{owner ? owner.name : ''}</span>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={4}>
                <span>Contact Number</span>
              </Col>
              <Col span={1}>
                <span>:</span>
              </Col>
              <Col span={18}>
                <span>{owner ? owner.contactNumber : ''}</span>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={4}>
                <span>Mailing Address</span>
              </Col>
              <Col span={1}>
                <span>:</span>
              </Col>
              {/*
          <Col span={19}><span>{owner?owner.mailingAddress:''}</span></Col>
          */}
              <Col span={18}>
                <span>
                  <TextArea
                    readOnly
                    style={{
                      fontWeight: 650,
                      fontSize: '1em',
                      resize: 'none',
                      padding: 0,
                      width: '300px',
                      height: '10 0px',
                      border: 'none',
                    }}
                    value={
                      this.state.owner ? this.state.owner.mailingAddress : ''
                    }
                    rows={4}
                  />
                </span>
              </Col>
            </Row>
          </Row>

          <br />

          <Row>
            <Table
              loading={this.state.loadingData}
              style={{}}
              dataSource={this.state.datasource}
              columns={columns}
              pagination={false}
              title={() => 'Statement Of Account'}
              footer={() => (
                <div>
                  <Row>
                    <Col span={5} offset={19}>
                      <span>Total : </span>
                      <span style={{ float: 'right' }}>
                        {isNaN(totalbalanceAmount)
                          ? '0.00'
                          : totalbalanceAmount >= 0
                            ? totalbalanceAmount
                            : '(' + Math.abs(totalbalanceAmount).toFixed(2) + ')'}
                      </span>
                    </Col>
                  </Row>
                </div>
              )}
              size={'small'}
            />

            <div className='invoiceFooter'>
              <div className='ql-display-container'>
                <div
                  className='ql-display-editor'
                  dangerouslySetInnerHTML={{
                    __html: this.state.setting ? this.state.setting.soaTnc : '',
                  }}
                />
              </div>
            </div>
          </Row>
        </Card>
      </Spin>
    );
  }
}

class IndexStatementOfAccount extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fromDate: '2020-05-27',
      toDate: new Date(),
    };
    //  this.fromDateOnChange = this.fromDateOnChange.bind(this);
  }

  fromDateOnChange = (e) => {
    this.setState({
      fromDate: moment(new Date(e[0])).format('YYYY-MM-DD'),
      toDate: moment(new Date(e[1])).format('YYYY-MM-DD'),
    });
  };

  toDateOnChange = (e) => {
    this.setState({
      toDate: moment(new Date(e)).format('YYYY-MM-DD'),
    });
  };

  render() {
    return (
      <Layout>
        <Row gutter={24}>
          <Col style={{ marginBottom: 10 }} span={6} offset={16}>
            <RangePicker onChange={this.fromDateOnChange} />
          </Col>

          <Col span={2} offset={0} style={{ marginBottom: 0 }}>
            <ReactToPrint
              trigger={() => <Button type='primary'>PRINT</Button>}
              content={() => this.componentRef}
            />
          </Col>
        </Row>

        <Row>
          <ComponentToPrint
            ref={(el) => (this.componentRef = el)}
            id={this.props.match.params.id}
            fromDate={this.state.fromDate}
            toDate={this.state.toDate}
            companyId={this.props.commons.selectedCompany._id}
          />
        </Row>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    //accounts: state.accounts
    commons: state.commons,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      //updateMode: updateMode
    },
    dispatch
  );
}

export default requireAuth(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Form.create()(IndexStatementOfAccount))
);
